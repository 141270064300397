<template>
  <el-card class="box-card">
    <el-row>
      <el-descriptions class="margin-top" title="" :column="3">
        <el-descriptions-item label="商品名称">{{ goodObj.sku_info.name }}</el-descriptions-item>
        <!--        <el-descriptions-item label="店铺名称">{{ row.coupon_branch }}</el-descriptions-item>-->
        <el-descriptions-item label="商品原售价">{{ goodObj.sku_info.price }}</el-descriptions-item>
        <el-descriptions-item label="返现售价">{{ goodObj.price }}</el-descriptions-item>
        <el-descriptions-item v-if="goodObj.type === 0" label="返现积分">{{
            goodObj.reach_condition
          }}
        </el-descriptions-item>
        <el-descriptions-item v-if="goodObj.type == 1" label="消费次数">{{ goodObj.reach_condition }}</el-descriptions-item>
        <el-descriptions-item v-if="goodObj.type == 2" label="消费金额数">{{
            goodObj.reach_condition
          }}
        </el-descriptions-item>
        <el-descriptions-item v-if="goodObj.type == 3" label="第三方条件">{{
            goodObj.reach_condition
          }}
        </el-descriptions-item>
        <el-descriptions-item label="参与人数">{{ goodObj.participation }}</el-descriptions-item>
        <el-descriptions-item label="达标返现人数">{{ goodObj.standards }}</el-descriptions-item>
        <!--        <el-descriptions-item label="第三方接口更新时间">{{ goodObj.third_party | formatDate }}</el-descriptions-item>-->
      </el-descriptions>
    </el-row>
    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="user_obj.name"
          label="用户名">
      </el-table-column>
      <el-table-column align="center" label="参与时间">
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        align="center"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetParticipationList} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      goodObj: {},
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetParticipationList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.goodObj = res.data.goods
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>

